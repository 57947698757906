import React from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo.js"

const ContactPage = () => {
    return ( 
            <Layout>
                <SEO title="Anonycoder Contact Page"/>
                <h1>Contact</h1>
                <p>Feel free to contact Anonycoder about any concern you may have.</p>
                <p>Email at <a href="contact.anonycoder@gmail.com">contact.anonycoder@gmail.com</a></p>
                
                </Layout>



     );
}
 
export default ContactPage;